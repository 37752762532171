import React, { useState, useEffect } from "react";

function ZakatCalculator() {
  const [nisabType, setNisabType] = useState("silver");
  const [formData, setFormData] = useState({
    cashInHand: "",
    cashDeposited: "",
    cashLoans: "",
    investments: "",
    goldValue: "",
    silverValue: "",
    stockValue: "",
    borrowedMoney: "",
    wagesDue: "",
    taxesDue: "",
  });

  const [totalNetWorth, setTotalNetWorth] = useState(0);
  const [zakatPayable, setZakatPayable] = useState(0);
  const [isCalculated, setIsCalculated] = useState(false);

  // Values and thresholds
  const goldNisabGrams = 87.48; // 7.5 tola
  const silverNisabGrams = 612.36; // 52.5 tola
  const goldRatePerGram = 25719; // PKR per gram (approximate)
  const silverRatePerGram = 286; // PKR per gram (approximate)
  const goldNisabValue = Math.round(goldNisabGrams * goldRatePerGram); // ~2,250,000 PKR
  const silverNisabValue = Math.round(silverNisabGrams * silverRatePerGram); // ~175,000 PKR

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numValue = value === "" ? "" : parseFloat(value);
    setFormData({ ...formData, [name]: numValue });
  };

  const handleNisabChange = (e) => {
    setNisabType(e.target.value);
  };

  // Add useEffect to automatically calculate values when any input changes
  useEffect(() => {
    calculateZakat();
  }, [formData, nisabType]);

  // Calculate function now runs automatically on input changes and with button click
  const calculateZakat = () => {
    // Calculate total assets
    const assets = [
      parseFloat(formData.cashInHand || 0),
      parseFloat(formData.cashDeposited || 0),
      parseFloat(formData.cashLoans || 0),
      parseFloat(formData.investments || 0),
      parseFloat(formData.goldValue || 0),
      parseFloat(formData.silverValue || 0),
      parseFloat(formData.stockValue || 0),
    ].reduce((sum, value) => sum + value, 0);

    // Calculate total liabilities
    const liabilities = [
      parseFloat(formData.borrowedMoney || 0),
      parseFloat(formData.wagesDue || 0),
      parseFloat(formData.taxesDue || 0),
    ].reduce((sum, value) => sum + value, 0);

    // Calculate net worth
    const netWorth = assets - liabilities;
    setTotalNetWorth(netWorth);

    // Calculate Zakat payable (2.5% of net worth) if above nisab threshold
    const nisabThreshold =
      nisabType === "gold" ? goldNisabValue : silverNisabValue;
    setZakatPayable(netWorth >= nisabThreshold ? netWorth * 0.025 : 0);

    // Set calculated state to true when calculations are done
    setIsCalculated(true);
  };

  // Custom styles that mimic Bootstrap but are scoped to this component
  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "4rem auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
    },
    card: {
      border: "1px solid #ddd",
      borderRadius: "4px",
      padding: "20px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      backgroundColor: "#fff",
    },
    row: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "-15px",
      marginRight: "-15px",
    },
    column: {
      flex: "1 0 100%",
      padding: "0 15px",
      marginBottom: "20px",
    },
    columnHalf: {
      flex: "1 0 100%",
      padding: "0 15px",
      marginBottom: "20px",
    },
    heading: {
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "15px",
      color: "#333",
    },
    subheading: {
      fontSize: "16px",
      fontWeight: "normal",
      marginBottom: "10px",
      color: "#333",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      fontSize: "14px",
      color: "#555",
    },
    inputGroup: {
      display: "flex",
      borderCollapse: "collapse",
    },
    inputGroupPrepend: {
      backgroundColor: "#f8f9fa",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      border: "1px solid #ced4da",
      borderRight: "none",
      display: "flex",
      alignItems: "center",
      padding: "0 10px",
    },
    input: {
      flex: "1",
      padding: "8px 12px",
      fontSize: "14px",
      border: "1px solid #ced4da",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      lineHeight: "1.5",
      color: "#495057",
      backgroundColor: "#fff",
      width: "100%",
    },
    radio: {
      marginRight: "10px",
    },
    radioLabel: {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
      cursor: "pointer",
    },
    radioInput: {
      marginRight: "8px",
    },
    footer: {
      backgroundColor: "#f8f9fa",
      padding: "20px",
      marginTop: "20px",
      borderRadius: "4px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    footerColumn: {
      flex: "1 0 100%",
      textAlign: "center",
      marginBottom: "15px",
    },
    footerTitle: {
      fontSize: "14px",
      color: "#666",
      marginBottom: "5px",
    },
    footerValue: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#28a745",
    },
    button: {
      backgroundColor: "#28a745",
      color: "#fff",
      border: "none",
      padding: "10px 30px",
      fontSize: "16px",
      borderRadius: "4px",
      cursor: "pointer",
      fontWeight: "bold",
      textAlign: "center",
      margin: "0 auto",
      display: "block",
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px",
    },
    nisabTypeHighlight: {
      fontWeight: "bold",
      color: "#28a745",
      textTransform: "capitalize",
    },
    infoBox: {
      backgroundColor: "#e9f5f2",
      padding: "15px",
      borderRadius: "4px",
      marginBottom: "20px",
      borderLeft: "4px solid #28a745",
    },
    infoTitle: {
      fontSize: "16px",
      fontWeight: "bold",
      marginBottom: "10px",
      color: "#333",
    },
    infoText: {
      fontSize: "14px",
      color: "#555",
      marginBottom: "5px",
    },
  };

  // Media query styles for responsive design
  if (typeof window !== "undefined" && window.innerWidth >= 768) {
    styles.columnHalf = {
      ...styles.columnHalf,
      flex: "0 0 50%",
    };
    styles.footerColumn = {
      ...styles.footerColumn,
      flex: "0 0 50%",
      marginBottom: 0,
    };
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.infoBox}>
          <div style={styles.infoTitle}>Current Nisab Information</div>
          <div style={styles.infoText}>
            You have selected the{" "}
            <span style={styles.nisabTypeHighlight}>{nisabType}</span> Nisab
            threshold
          </div>
          <div style={styles.infoText}>
            <strong>Gold Nisab:</strong> {goldNisabGrams} grams (7.5 tola) ≈ PKR{" "}
            {goldNisabValue.toLocaleString()}
          </div>
          <div style={styles.infoText}>
            <strong>Silver Nisab:</strong> {silverNisabGrams} grams (52.5 tola)
            ≈ PKR {silverNisabValue.toLocaleString()}
          </div>
          <div style={styles.infoText}>
            <strong>Current threshold:</strong> PKR{" "}
            {nisabType === "gold"
              ? goldNisabValue.toLocaleString()
              : silverNisabValue.toLocaleString()}
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <h5 style={styles.heading}>Select Nisab Type</h5>
          <div style={styles.radioLabel}>
            <input
              type="radio"
              id="goldNisab"
              name="nisabType"
              value="gold"
              checked={nisabType === "gold"}
              onChange={handleNisabChange}
              style={styles.radioInput}
            />
            <label htmlFor="goldNisab">
              Value of Gold ({goldNisabGrams} grams | 7.5 tola) ≈ PKR{" "}
              {goldNisabValue.toLocaleString()}
            </label>
          </div>
          <div style={styles.radioLabel}>
            <input
              type="radio"
              id="silverNisab"
              name="nisabType"
              value="silver"
              checked={nisabType === "silver"}
              onChange={handleNisabChange}
              style={styles.radioInput}
            />
            <label htmlFor="silverNisab">
              Value of Silver ({silverNisabGrams} grams | 52.5 tola) ≈ PKR{" "}
              {silverNisabValue.toLocaleString()}
            </label>
          </div>
        </div>

        <div style={styles.row}>
          <div style={styles.columnHalf}>
            <h5 style={styles.heading}>Cash Assets</h5>
            <div style={styles.formGroup}>
              <label style={styles.label}>
                Cash in hand & in bank accounts
              </label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="cashInHand"
                  value={formData.cashInHand}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>
                Cash deposited for some future purpose, e.g. Hajj
              </label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="cashDeposited"
                  value={formData.cashDeposited}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Cash given out in loans</label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="cashLoans"
                  value={formData.cashLoans}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>
                Investments, shares, saving certificates, pensions funded by
                money in one's possession
              </label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="investments"
                  value={formData.investments}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
          </div>

          <div style={styles.columnHalf}>
            <h5 style={styles.heading}>Liabilities</h5>
            <div style={styles.formGroup}>
              <label style={styles.label}>
                Borrowed money, goods bought on credit
              </label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="borrowedMoney"
                  value={formData.borrowedMoney}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>Wages due to employees</label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="wagesDue"
                  value={formData.wagesDue}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
            <div style={styles.formGroup}>
              <label style={styles.label}>
                Taxes, Rent, utility bills due immediately
              </label>
              <div style={styles.inputGroup}>
                <div style={styles.inputGroupPrepend}>PKR</div>
                <input
                  type="number"
                  style={styles.input}
                  name="taxesDue"
                  value={formData.taxesDue}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <h5 style={styles.heading}>Precious Metals</h5>
          <div style={styles.row}>
            <div style={styles.columnHalf}>
              <div style={styles.formGroup}>
                <label style={styles.label}>
                  Value of Gold that you possess
                </label>
                <div style={styles.inputGroup}>
                  <div style={styles.inputGroupPrepend}>PKR</div>
                  <input
                    type="number"
                    style={styles.input}
                    name="goldValue"
                    value={formData.goldValue}
                    onChange={handleInputChange}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
            <div style={styles.columnHalf}>
              <div style={styles.formGroup}>
                <label style={styles.label}>
                  Value of Silver that you possess
                </label>
                <div style={styles.inputGroup}>
                  <div style={styles.inputGroupPrepend}>PKR</div>
                  <input
                    type="number"
                    style={styles.input}
                    name="silverValue"
                    value={formData.silverValue}
                    onChange={handleInputChange}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <h5 style={styles.heading}>Trade Goods</h5>
          <div style={styles.formGroup}>
            <label style={styles.label}>Value of stock</label>
            <div style={styles.inputGroup}>
              <div style={styles.inputGroupPrepend}>PKR</div>
              <input
                type="number"
                style={styles.input}
                name="stockValue"
                value={formData.stockValue}
                onChange={handleInputChange}
                placeholder="0"
              />
            </div>
          </div>
        </div>

        <div style={styles.footer}>
          <div style={styles.footerColumn}>
            <div style={styles.footerTitle}>
              TOTAL NET WORTH
              {isCalculated &&
                totalNetWorth <
                  (nisabType === "gold" ? goldNisabValue : silverNisabValue) &&
                " (Below Nisab)"}
            </div>
            <div
              style={{
                ...styles.footerValue,
                color: "#28a745",
              }}
            >
              PKR{" "}
              {totalNetWorth.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </div>
          </div>
          <div style={styles.footerColumn}>
            <div style={styles.footerTitle}>
              ZAKAT PAYABLE
              <span
                style={{
                  ...styles.nisabTypeHighlight,
                  color: "#28a745",
                }}
              >
                {" "}
                ({nisabType} Nisab)
              </span>
            </div>
            <div
              style={{
                ...styles.footerValue,
                color: "#28a745",
              }}
            >
              PKR{" "}
              {zakatPayable.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </div>
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <button style={styles.button} onClick={calculateZakat}>
            Calculate & Give Zakat
          </button>
        </div>
      </div>
    </div>
  );
}

export default ZakatCalculator;
