const PATH = {
  HOME: "/",
  DOMAINS: "#domains",
  VOLUNTEER: "/volunteer",
  CAMPAIGNS: "/campaigns",
  CONTACT: "/contact",
  ABOUT: "/about",
  DONATE: "/donate",
  CITIES: "/cities",
  ZAKAAT: "/zakaat",
};

export default PATH;
