import dummyImage from "assets/img/dummy.svg";
import mkp4 from "assets/img/mkp-4-1.png";
const campaignData = [
  {
    id: 0,
    name: "Mission Kaar e Kamal Pakistan",
    image: dummyImage,
    description:
      "Kaar-e-Kamal continues to be a beacon of hope and community service in its fourth year with the highly impactful campaign, Mission Kaar-e-Kamal Pakistan. This initiative is a testament to the power of collective goodwill, providing an empowering platform for individuals to contribute meaningfully to their communities. From uplifting the underprivileged to addressing local challenges, Kaar-e-Kamal enables volunteers to make a difference in their hometowns while fostering a sense of unity and shared purpose. As the campaign enters its fourth year, it reflects on the transformative impact made across various cities in Pakistan. Each year, more and more volunteers have come forward to serve humanity, proving that one act of kindness can inspire many. Mission Kaar-e-Kamal Pakistan is more than a campaign; it’s a movement to create lasting change, strengthen the bonds of our communities, and showcase the extraordinary spirit of compassion that defines our nation. Now is your chance to join this journey and leave your mark!",
  },
  {
    id: 1,
    name: "South Punjab Campaign",
    image: dummyImage,
    description:
      "Kaar-e-Kamal helped in Flood Campaign in different areas of Pakistan like Rajanpur, Fazilpur, Taunsa Sharif and many areas of Sindh in Tandoo M Khan.",
  },
  { id: 2, name: "East Punjab Campagin", image: "", description: "" },
  { id: 3, name: "Syria Campaign", image: "", description: "" },
  { id: 4, name: "Palestine Campaign", image: "", description: "" },
];

export default campaignData;
