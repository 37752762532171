import React from "react";

function DividedSection({ heading, text, image, right, className }) {
  return (
    <div
      className={`${className} p-8 md:p-auto w-full h-full flex flex-col my-10 md:my-24 ${
        right && "text-right items-end"
      }`}
    >
      <span className="bg-gradient-to-br from-theme-mustard to-theme-dark-mustard h-2 w-48 rounded-full"></span>
      <h2 className="font-semibold text-2xl md:text-5xl my-6">{heading}</h2>
      <div
        className={`flex flex-col md:flex-row justify-between md:items-center ${
          right && "flex-row-reverse text-right"
        }`}
      >
        <p className="w-[80%] text-sm md:text-md md:w-[40%] text-black/50 h-full ">
          {text}
        </p>
        <img
          src={image}
          alt={text}
          className="w-[80%] md:w-auto mt-10 md:mt-auto"
        />
      </div>
    </div>
  );
}

export default DividedSection;
