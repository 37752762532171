import PATH from "./PathConstant";

const NAV_LIST = [
	{id: 0, text: "Home", href: PATH.HOME},
	// { id: 1, text: "Domains", href: PATH.DOMAINS },
	{id: 2, text: "Volunteer", href: PATH.VOLUNTEER},
	// { id: 3, text: "Campaigns", href: PATH.CAMPAIGNS },
	{id: 4, text: "Contact Us", href: PATH.CONTACT},
	// { id: 5, text: "About", href: PATH.ABOUT },
	{ id: 6, text: "Zakaat", href: PATH.ZAKAAT },
];

export default NAV_LIST;
