import City from "pages/City";
import Donate from "pages/Donate";
import { Route, Routes } from "react-router";
import Layout from "../layout/Layout";
import Home from "../pages/Home";
import PATH from "../utils/constants/PathConstant";
import Contact from "pages/Contact";
import ZakatCalculator from "../pages/Zakaat";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route
        path={PATH.HOME}
        element={
          <Layout>
            <Home />
          </Layout>
        }
      ></Route>
      <Route
        path={`${PATH.CITIES}/:cityId`}
        element={
          <Layout>
            <City />
          </Layout>
        }
      />
      <Route
        path={PATH.DONATE}
        element={
          <Layout>
            <Donate />
          </Layout>
        }
      />
      <Route
        path={PATH.CONTACT}
        element={
          <Layout>
            <Contact />
          </Layout>
        }
      />
      <Route
        path={PATH.ZAKAAT}
        element={
          <Layout>
            <ZakatCalculator />
          </Layout>
        }
      />
    </Routes>
  );
};

export default PublicRoutes;
